import React, { useEffect, useRef, useState } from 'react';
import { cn } from '../../utils/cn';
import { useInViewport } from 'ahooks';

interface LogoAnimatedProps {
    /** Logo size in rems */
    size: number;

    /** Controls how the animation is triggered - on hover or plays automatically (single / loop) */
    mode?: 'disabled' | 'hover' | 'single' | 'loop';

    /** Delay in milliseconds before the animation starts, used when mode is 'single' or 'loop' */
    delay?: number;

    /** Whether to enable the rolling text animation effect */
    roll?: boolean;

    /** Delay in milliseconds between loops */
    loopDelay?: number;

    /** Whether to enable the legacy rem mode */
    isLegacyBrokenRemMode?: boolean;

    /** Whether to enable the transparent mode */
    color?: 'light' | 'dark';
}

export const LogoAnimated = (props: LogoAnimatedProps) => {
    const {
        size,
        mode = 'hover',
        delay = 1000,
        roll = true,
        loopDelay = 5000,
        isLegacyBrokenRemMode = false,
        color = 'dark',
    } = props;

    const ref = useRef(null);
    const [inViewport] = useInViewport(ref);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playedCount, setPlayedCount] = useState(0);

    const rollTexts = ['PRO', 'PRODUCTS', 'PROS', 'PROJECTS'];

    useEffect(() => {
        const hasPlayed = playedCount > 0;

        if (
            (mode === 'loop' && (inViewport || hasPlayed)) ||
            (mode === 'single' && inViewport && !hasPlayed)
        ) {
            const timer = setTimeout(
                () => {
                    setIsPlaying(true);
                },
                playedCount === 0 ? delay : loopDelay,
            );

            return () => clearTimeout(timer);
        }
    }, [mode, inViewport, delay, playedCount, loopDelay]);

    useEffect(() => {
        if (isPlaying) {
            const timer = setTimeout(() => {
                setIsPlaying(false);
                setPlayedCount(playedCount + 1);
            }, 5500);

            return () => clearTimeout(timer);
        }
    }, [isPlaying, playedCount]);

    const logoMiddleCharClasses =
        size < 2.5
            ? 'group-hover:px-1 group-data-[animating=true]/logo:px-1'
            : 'group-hover:px-2 group-data-[animating=true]/logo:px-2';
    const logoClassNames =
        'absolute w-full whitespace-nowrap text-left will-change-transform [-webkit-backface-visibility:hidden] [backface-visibility:hidden]';

    const getLogoRollTextStyles = (index: number) => {
        return {
            transform: `rotateX(${index * 90}deg) translateZ(${size * -8}px)`,
        };
    };

    const logoRotationProperties = (
        roll
            ? {
                  '--rotation-angle': '90deg',
                  '--rotation-duration': '5s',
              }
            : {}
    ) as React.CSSProperties;

    const containerProps = {
        ref,
        'data-animating': isPlaying,
        style: {
            fontSize: `${isLegacyBrokenRemMode ? size * 16 : size}rem`,
        },
        className: cn(
            'flex font-ap-display antialiased items-center justify-start leading-none group/logo',
            mode === 'hover' ? 'group' : undefined,
            color === 'light'
                ? 'text-white hover:text-white'
                : 'text-black hover:text-black',
        ),
    };

    return (
        <div {...containerProps}>
            <span>ARCH</span>
            <span
                className={cn(
                    'transition-all skew-x-0 px-0 group-hover:skew-x-[-26deg] group-data-[animating=true]/logo:skew-x-[-26deg]',
                    logoMiddleCharClasses,
                )}
            >
                I
            </span>
            {mode !== 'disabled' && roll ? (
                <div
                    className="relative"
                    style={{
                        height: `${isLegacyBrokenRemMode ? size * 16 : size}rem`,
                    }}
                >
                    <div
                        className={cn(
                            'transform-gpu absolute w-full h-full will-change-transform [transform-style:preserve-3d] [transform-origin:50%_50%] group-hover:animate-text-rotate',
                            isPlaying ? 'animate-text-rotate' : undefined,
                        )}
                        style={logoRotationProperties}
                    >
                        {rollTexts.map((t, i) => (
                            <div
                                className={logoClassNames}
                                style={getLogoRollTextStyles(i)}
                                key={i}
                            >
                                {t}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <span>PRO</span>
            )}
        </div>
    );
};
